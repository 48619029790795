<template>
    <div>
        <h2 class="mb-5">Child list</h2>
        <v-data-table
            :headers="requestHeaders"
            :items="childList"
            :items-per-page="15"
            :loading="isLoading"
            @click:row="handleClick"
            class="elevation-1 request_list">
        </v-data-table>
    </div>
</template>

<script>
    export default {
        mounted() {
            this.getData();
        },

        data: () => ({
            childList: [],
            requestHeaders: [],
            isLoading: true,
        }),

        methods: {
            handleClick(item) {
                console.log(item);
            },
            async getData() {
                this.isLoading = true;
                await this.$http.get('/childs').then((response) => {
                    this.requestHeaders = response.data.data.parentChildList.headers;
                    this.childList = response.data.data.parentChildList.list;
                }).catch(() => {});
                this.isLoading = false;
            }    
        }
    }
</script>

<style scoped>

</style>